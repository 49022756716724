import { css } from '@emotion/react';
import React from 'react';
import { Helmet } from 'react-helmet';

import AboutConfig from '../../content/page/about.json';
import { Footer } from '../components/Footer';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import IndexLayout from '../layouts';
import { Translate } from '../locales/i18n';
import { colors } from '../styles/colors';
import { inner, outer, SiteArchiveHeader, SiteMain, SiteNavMain } from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>{Translate.about.title}</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">{Translate.about.title}</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>{AboutConfig.mainTitle}</h5>
                {AboutConfig.firstBlock && <p>{AboutConfig.firstBlock}</p>}
                {AboutConfig.video && (
                  <div className="post-content-video">
                    <iframe
                      allowFullScreen
                      width="560"
                      height="315"
                      src={AboutConfig.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                    <br />
                  </div>
                )}
                {AboutConfig.secondBlock && <p>{AboutConfig.secondBlock}</p>}
                {AboutConfig.thirdBlock && <p>{AboutConfig.thirdBlock}</p>}
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
